<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading variant="dark" />
    </div>
    <div class="container main" v-else>
      <div class="bg-white p-3"><h5>กรอกข้อมูลการซื้อเพื่อสะสมคะแนน</h5></div>
      <div class="my-3">กรุณากรอกข้อมูลด้านล่าง</div>
      <div
        class="bg-white h-screen p-3 d-flex flex-column justify-content-between"
      >
        <div>
          <InputText
            v-model="form.ticket_no"
            textFloat="หมายเลขใบเสร็จ"
            placeholder="หมายเลขใบเสร็จ ตัวอย่าง 101007103"
            type="text"
            name="ticket_no"
            class="f-regular"
            :isValidate="$v.form.ticket_no.$error"
            :v="$v.form.ticket_no"
          />
          <InputText
            v-model="form.grand_total"
            textFloat="ยอดสุทธิ"
            placeholder="กรุณากรอกยอดซื้อสุทธิ"
            type="number"
            name="grand_total"
            class="f-regular"
            :isValidate="$v.form.grand_total.$error"
            :v="$v.form.grand_total"
          />
        </div>
        <div>
          <b-button pill variant="primary" block @click="submitForm"
            >ยืนยัน</b-button
          >
        </div>
      </div>
    </div>
    <ModalAlert ref="modalAlert" :text="msg" closeText="รับทราบ" />
    <ModalLoading ref="modalLoading" />
  </div>
</template>

<script>
import InputText from "@/components/input/InputText";
import { required } from "vuelidate/lib/validators";
import ModalAlert from "@/components/alert-modal/ModalAlert";
import ModalLoading from "@/components/other/ModalLoading";
import OtherLoading from "@/components/other/OtherLoading";
export default {
  components: {
    InputText,
    ModalAlert,
    ModalLoading,
    OtherLoading
  },
  // head: {
  //   // creates a title tag in header.
  //   title() {
  //     return {
  //       inner: this.title
  //     };
  //   },
  //   meta: [
  //     // creates a meta description tag in header.
  //     { name: "description", content: "My description" }
  //   ]
  // },
  data() {
    return {
      isLoading: true,
      form: {
        ticket_no: "",
        telephone: "",
        grand_total: ""
      },
      msg: "ข้อมูลของคุณถูกบันทึกเรียบร้อย คุณจะได้รับคะแนนเมื่อรายละเอียดถูกยืนยัน"
    };
  },
  validations() {
    return {
      form: {
        ticket_no: { required },
        grand_total: { required }
      }
    };
  },
  created() {
    this.liffLogin();
  },
  methods: {
    async liffLogin() {
      this.isLoading = true;
      await this.$store.dispatch("lineLiffLogin", this.$Endpoint + "kis");
      const profile = await this.$store.dispatch("getUserApi");
      this.form.telephone = profile.telephone;
      this.isLoading = false;
    },
    async submitForm() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.$refs.modalLoading.show();
      const resp = await this.$axios.post(
        `https://dev-crm-sale-order-api.dosetech.co/api/saleOrder/customer`,
        this.form
      );
      this.$refs.modalLoading.hide();
      if (resp.result) {
        this.$refs.modalAlert.show();
        this.form = {
          ticket_no: "",
          telephone: this.form.telephone,
          grand_total: ""
        };
        this.$v.form.$reset();
      } else {
        this.msg = resp.message;
        this.$refs.modalAlert.show();
      }
    }
  }
};
</script>

<style scoped>
.main {
  background-color: whitesmoke;
  min-height: calc(100vh - 80px);
}
.h-screen {
  min-height: 75vh;
}
h5 {
  color: rgb(2, 17, 64);
  font-weight: bold;
}
</style>
